import React from "react";
import Lead from "../components/sections/lead";
import ContactForm from "../components/sections/contactForm";
import Footer from "../components/sections/footer";
import leadVideo from "../video/homepage.mp4";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Contacts = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru'/>
        <title>{t('lead.seo.contacts')}</title>
      </Helmet>
      <Lead
        subtitle={t('lead.contacts.pre-title')}
        title={t('lead.contacts.main-text')}
        leadPhoneText={t('lead.contacts.phone-text')}
        leadPhoneNumber={`8 800 200-29-29`}
        leadVideo={leadVideo}
        leadEmail={`info@aquaart.ru`}
        videoClass={`lead__video-wrapper--big`}
      />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contacts;
